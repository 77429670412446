import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Img from "gatsby-image"
import styled from "styled-components"
import { Helmet } from "react-helmet"

const Article = styled.section`
    width: 80%;
    max-width: 1080px;
    margin: auto;
    padding-bottom: 40px;
    @media only screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr 250px;
        grid-gap: 0 50px;
    }
`
const ArticleBody = styled.div `
    padding-bottom: 20px;
`

const RecentArticles = styled.div`
    h2 {
        font-size: 20px;
        margin-top: 28px;
    }
    a.articleLink {
        display: inline-block;
        color: var(--accent-turquoise-400);
        text-decoration: none;
        margin-bottom: 10px;
        font-size: 18px;
    }
    a.articleLink:hover {
        text-decoration: underline;
    }
`

export default function NewsArticle({ data }) {
    const article = data.markdownRemark

    let featuredImgFluid = article.frontmatter.featuredImage.childImageSharp.fluid

    return (
        <Layout>
            <Helmet>
                <meta charSet="UTF-8" />
                <title>{article.frontmatter.title} | Caplin Solar</title>
                <html lang="en"></html>
            </Helmet>
            <Article>
                <ArticleBody>
                    <h1>{article.frontmatter.title}</h1>
                    <p style={{color: "var(--neutral-500)"}}>{article.frontmatter.date}</p>
                    <Img style={{margin: "30px 0 30px 0"}} fluid={featuredImgFluid} />
                    <div dangerouslySetInnerHTML={{ __html: article.html }} />
                </ArticleBody>
                <RecentArticles>
                    <h2>Recent Articles</h2>
                    {data.allMarkdownRemark.edges.map(({ node }) => (
                        <div key={node.id}>
                            <Link className="articleLink" to={node.fields.slug}>{node.frontmatter.title}</Link>
                        </div>
                    ))}
                </RecentArticles>
            </Article>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                date(formatString: "DD MMMM YYYY")
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 960, quality: 72) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        }
        allMarkdownRemark(limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
            totalCount
            edges {
              node {
                id
                frontmatter {
                  title
                }
                fields {
                  slug
                }
              }
            }
          }
    }
`